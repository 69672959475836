import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0adeaaf5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal" }
const _hoisted_2 = { class: "error-view" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "error-view-text-wrapper" }
const _hoisted_5 = {
  key: 0,
  class: "error-view-title"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        style: _normalizeStyle(`width: ${_ctx.type === 'success' ? 68 : 120}px; height: ${
          _ctx.type === 'success' ? 68 : 129
        }px`),
        src: 
          require(`@/assets/front/${
            _ctx.type === 'success'
              ? 'group.png'
              : 'illustration-error-comunitation-down-copy.png'
          }`)
        
      }, null, 12, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        (!_ctx.hidetitle)
          ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.type === "success" ? "Éxito" : "Se ha producido un error"), 1))
          : _createCommentVNode("", true),
        _createElementVNode("p", {
          class: _normalizeClass(["error-view-subtitle", { 'normal-text': _ctx.hidetitle }])
        }, _toDisplayString(_ctx.message), 3),
        (!_ctx.hidetitle)
          ? (_openBlock(), _createElementBlock("button", {
              key: 1,
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.click && _ctx.click(...args))),
              class: "cta"
            }, _toDisplayString("Volver")))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}