<template>
  <div class="component-ui-input">
    <input
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      ref="input"
      v-bind:type="type"
      field="user normalcase"
      v-bind:placeholder="placeholder"
    />
    <div class="component-ui-hint"></div>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
export default {
  name: "CoUiInput",
  props: ["modelValue", "type", "placeholder"],
  emits: ["update:modelValue"],
};
</script>

<style>
.component-ui-input,
ui-input {
  text-align: center;
}

.component-ui-input input {
  background-color: transparent;
  border: 1px solid #d7d2cb;
  padding: 1em 3em;
  margin: 1em 0.9em 0em 0em;
  width: 72%;
  border-radius: 0.1em;
}
</style>
