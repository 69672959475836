import { createI18n } from "vue-i18n";
import * as es from "./strings/es.json";
import * as en from "./strings/en.json";
import * as fr from "./strings/fr.json";
import * as be from "./strings/be.json";
import * as br from "./strings/br.json";
import * as de from "./strings/de.json";
import * as it from "./strings/it.json";
import * as nl from "./strings/nl.json";
import * as pt from "./strings/pt.json";

const messages = {
  es,
  en,
  be,
  fr,
  br,
  de,
  it,
  nl,
  pt,
};

export default createI18n({
  locale: "es",
  fallbackLocale: "en",
  messages,
});
