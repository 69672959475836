<template>
  <div class="component-ui-form-button">
    <button
      class="button"
      v-on:click.prevent="onClick"
      :disabled="show || !isCompleteForm"
      v-bind:class="{ disabled: show || !isCompleteForm }"
    >
      {{ text }}
    </button>
    <template v-if="show">
      <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "CoUiFormButton",
  props: {
    text: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    onClick: {
      type: Function,
      required: true,
    },
    isCompleteForm: {
      type: Boolean,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
};
</script>

<style lang="less">
.component-ui-form-button {
  color: #fff;
  padding: 0.5em 0 0.5em;
  width: 100%;
}

.button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: normal;
  background-color: #0072ce !important;
  padding: 20px 0;
  border-radius: 14px;
  min-height: 0;
  cursor: pointer;
  margin: 0 auto;
  border-radius: 0.2em;
  transition: width 0.4s;
  border: none;
  color: white;
  transition: 0.5s;
}

.button:hover {
  //text-decoration: underline;
  text-decoration-color: white;
  background-color: #0061af;
}

.disabled {
  background-color: #b0b1b1 !important;
  pointer-events: none;
  cursor: default;
}

.spinner {
  height: 20px;
  position: relative;
}

.double-bounce1,
.double-bounce2 {
  width: 8.5em;
  border-radius: 50%;
  opacity: 0.9;
  position: relative;
  margin: 0 auto;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce1 {
  background-color: #0072ce;
  border: 1px solid #00beff;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
  background-color: #00beff;
  border: 1px solid #0072ce;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
</style>
