<template>
  <div class="language" v-if="isMultiLanguage()">
    <ul class="horizontal-list">
      <li
        @click="changeLang(lang), getCountries()"
        style="cursor: pointer"
        v-for="(lang, index) in availableLangs()"
        :key="lang"
      >
        <span :class="{ selected: lang === selectedLang }">
          {{ lang.toLocaleUpperCase() }}
        </span>
        <span v-if="index !== availableLangs().length - 1"> | </span>
      </li>
    </ul>
  </div>

  <div class="form-base">
    <ErrorModal
      v-if="feedback.visible"
      :message="feedback.message"
      :type="feedback.type"
      :click="
        () => {
          feedback.visible = false;
        }
      "
    />
    <div class="subset-row header" style="justify-content: space-between">
      <div>
        <h2 class="font-black">{{ $t(`signup.header.${partner}`) }}</h2>
        <h1 class="font-black">{{ $t("signup.header2") }}</h1>
      </div>

      <img
        :alt="`logo ${partner}`"
        :class="{ 'oovv-img': partner === 'oovv' }"
        style="width: 20%; height: 20%"
        v-bind:src="require(`@/assets/front/${partner}.png`)"
      />
    </div>

    <p v-html="$t(`signup.description.${partner}`)"></p>
    <form
      @submit.prevent="submitButtonAction"
      style="margin-top: 20px"
      class="container mx-auto"
    >
      <fieldset>
        <legend>{{ $t(`signup.fieldset_personal.legend.${partner}`) }}</legend>
        <div class="subset-row">
          <div class="col-1" style="padding-right: 0.5rem">
            <label for="treatment">{{
              $t("signup.fieldset_personal.tratamiento")
            }}</label>
            <select v-model="form.title" id="treatment" name="select">
              <option value="MR">
                {{ $t("signup.fieldset_personal.mr") }}
              </option>
              <option value="MRS">
                {{ $t("signup.fieldset_personal.mrs") }}
              </option>
            </select>
          </div>
          <div class="col-2 margin-mobile-top">
            <label for="name">{{
              $t("signup.fieldset_personal.name_label")
            }}</label>
            <input
              v-model="form.name"
              id="name"
              required
              field="user normalcase"
              :placeholder="$t('signup.fieldset_personal.name_label')"
            />
          </div>
        </div>
        <div class="subset-row">
          <div class="col-2">
            <label for="first_last_name">{{
              $t("signup.fieldset_personal.firstname_label")
            }}</label>
            <input
              v-model="form.firstSurname"
              required
              id="first_last_name"
              field="user normalcase"
              :placeholder="$t('signup.fieldset_personal.firstname_label')"
            />
          </div>
          <div class="col-2 margin-mobile-top" v-if="$i18n.locale != 'en'">
            <label for="second_last_name">{{
              $t("signup.fieldset_personal.secondname_label")
            }}</label>
            <input
              v-model="form.secondSurname"
              id="second_last_name"
              field="user normalcase"
              :placeholder="$t('signup.fieldset_personal.secondname_label')"
            />
          </div>
        </div>
        <div class="subset-row">
          <div class="col-2">
            <label for="birth_date">{{
              $t("signup.fieldset_personal.born_label")
            }}</label>
            <input
              type="date"
              v-model="form.birthDate"
              required
              id="birth_date"
              field="user normalcase"
              placeholder="dd/MM/yyyy"
            />
          </div>
        </div>
        <div class="subset-row">
          <div class="col-1" style="padding-right: 0.5rem">
            <label for="doc_type">{{
              $t("signup.fieldset_personal.doctype_label")
            }}</label>
            <select
              v-model="form.identificationDocument.type"
              id="doc_type"
              name="doc_type"
            >
              <option
                v-for="docType in docTypes()"
                :key="docType.code"
                :value="docType.code"
              >
                {{ docType.name }}
              </option>
            </select>
          </div>
          <div class="col-2 margin-mobile-top">
            <label for="doc_num">{{
              $t("signup.fieldset_personal.doc_label")
            }}</label>
            <input
              v-model="form.identificationDocument.number"
              required
              id="doc_num"
              field="user normalcase"
              :placeholder="$t('signup.fieldset_personal.doc_label')"
            />
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend>{{ $t("signup.fieldset_contact.legend") }}</legend>
        <div class="subset-row">
          <div class="col-2">
            <label for="email">{{
              $t("signup.fieldset_contact.email_label")
            }}</label>
            <input
              v-model="form.email"
              id="email"
              required
              type="email"
              field="user normalcase"
              :placeholder="$t('signup.fieldset_contact.email_label')"
            />
          </div>
        </div>
        <div class="subset-row">
          <div class="col-1 margin-mobile-top" style="padding-right: 0.5rem">
            <label for="prefijo">{{
              $t("signup.fieldset_contact.prefijo_label")
            }}</label>
            <v-combobox
              onclick="this.select()"
              id="prefijo"
              name="prefijo"
              class="comboCustom"
              v-model="comboCountry"
              :items="countries"
              item-title="description"
              item-value="phonePrefix"
              variant="outlined"
              auto-select-first="exact"
            ></v-combobox>
          </div>
          <div class="col-3 margin-mobile-top">
            <label for="doc_num">{{
              $t("signup.fieldset_contact.tel_label")
            }}</label>
            <input
              v-model="form.telephone.number"
              type="tel"
              required
              id="doc_num"
              field="user normalcase"
              :placeholder="$t('signup.fieldset_contact.tel_label')"
            />
          </div>
        </div>
      </fieldset>
      <div class="component-ui-form-button">
        <input
          class="button"
          type="submit"
          :value="$t('signup.cta')"
          :disabled="isFormButtonDisabled"
          v-bind:class="{ disabled: isFormButtonDisabled }"
        />
        <template v-if="operating">
          <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
          </div>
        </template>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { preAlta, formEmpty, countries } from "../../api/preAlta";
import { defineComponent } from "vue";
import ErrorModal from "@/components/common/ErrorModal.vue";
import { Channel } from "@/models/channels";
import { ComboCountry } from "@/models/country.interface";
import { VCombobox } from "vuetify/components/VCombobox";

export default defineComponent({
  components: {
    ErrorModal,
    VCombobox,
  },
  data() {
    return {
      countries: [] as ComboCountry[],
      selectedLang: encodeURI(this.$route.params.lang as string),
      comboCountry: {} as ComboCountry,
      operating: false,
      feedback: {
        visible: false,
        type: "success",
        message: "",
      },
      form: formEmpty(String(this.$route.params.channel)),
      partner: String(this.$route.params.channel),
      docTypes: () => {
        let v;
        if (this.$i18n.locale != "en") {
          v = [
            {
              code: "NI",
              name: "DNI",
            },
            {
              code: "PP",
              name: "Pasaporte",
            },
            {
              code: "DE",
              name: "ID Extranjero",
            },
            {
              code: "ID",
              name: "NIE",
            },
          ];
        } else {
          v = [
            {
              code: "PP",
              name: "Passport",
            },
            {
              code: "DE",
              name: "Foreign ID",
            },
            {
              code: "ID",
              name: "NIE",
            },
            {
              code: "NI",
              name: "DNI",
            },
          ];
        }
        return v;
      },
    };
  },
  mounted() {
    this.getCountries();
  },
  computed: {
    isCompleteForm(): boolean {
      return this.validate();
    },
    isFormButtonDisabled(): boolean {
      return this.operating || !this.isCompleteForm;
    },
  },
  methods: {
    validate(): boolean {
      let success = true;
      if (this.form.name === "" || this.form.name === null) {
        success = false;
      }
      if (this.form.firstSurname === "" || this.form.firstSurname === null) {
        success = false;
      }
      if (this.form.birthDate === null) {
        success = false;
      }
      if (
        this.form.identificationDocument.number === "" ||
        this.form.identificationDocument.number === null
      ) {
        success = false;
      }
      if (this.form.email === "" || this.form.email === null) {
        success = false;
      }
      if (this.form.telephone.number === null) {
        success = false;
      }

      return success;
    },

    changeLang(lang: string) {
      const channel: Channel = this.$route.params.channel as Channel;
      this.$router.push({ path: `/signup/${channel}/${lang}`, replace: true });
      this.selectedLang = lang;
      this.$i18n.locale = lang;
    },
    getCountries() {
      countries(this.selectedLang).then((res) => {
        this.countries = res.data.results.map((country) => ({
          description: country.phonePrefix + " (" + country.description + ")",
          phonePrefix: country.phonePrefix.slice(1),
        }));
        this.setDefaultCountryValue();
      });
    },
    setDefaultCountryValue() {
      const countryPref = this.form.telephone.prefix.toString();
      const countryDes = this.countries.find(
        (country) => country.phonePrefix === countryPref
      )?.description;

      this.comboCountry = {
        description: countryDes || "+34 (España)",
        phonePrefix: countryPref || "34",
      };
    },
    isMultiLanguage() {
      const channel: Channel = this.$route.params.channel as Channel;
      const multiLangChannels = process.env.VUE_APP_MULTILANG_CHANNELS || [];
      return multiLangChannels.includes(channel);
    },
    availableLangs() {
      const channel: Channel = this.$route.params.channel as Channel;
      const envKey = `VUE_APP_${channel.toUpperCase()}_LANGUAGES`;
      const langAvailable = process.env[envKey].split(",") || [];
      return langAvailable;
    },

    submitButtonAction() {
      let channel: string = this.$route.params.channel as string;
      this.operating = true;
      this.form.telephone.prefix = this.comboCountry.phonePrefix;
      preAlta(this.form, channel, this.$i18n.locale)
        .then(() => {
          this.operating = false;
          this.form = formEmpty(String(this.$route.params.channel));
          this.feedback = {
            message: this.$t("signup.success"),
            type: "success",
            visible: true,
          };
        })
        .catch((err: any) => {
          this.operating = false;

          this.feedback = {
            message: err.response.data.errorDescription,
            type: "error",
            visible: true,
          };
        });
    },
  },
});
</script>

<style scoped>
.form-base {
  text-align: left;
  width: 100%;
}
fieldset {
  display: flex;
  flex-direction: column;
  border: none;
  padding: 0px;
  padding-bottom: 24px;
}

.subset-row {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.col-1 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
}
.col-2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 2;
}
.col-3 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 3;
}
h1 {
  font-size: 36px;
  color: rgb(15, 35, 103);
  margin-top: 0px;
  font-family: Aeradar-Bold, sans-serif;
  font-weight: 600;
  margin-bottom: 0px;
}

h2 {
  font-size: 24px;
  font-family: Aeradar-Bold, sans-serif;
  font-weight: 600;
  color: #000000de;
  margin-bottom: 0px;
}

form select {
  background-color: white;
  border: 1px solid #d7d2cb;
  padding: 1em 1em;
  margin: 0.75em 0.75em 0em 0em;
  width: 100%;
  border-radius: 0.1em;
}

form input {
  background-color: white;
  border: 1px solid #d7d2cb;
  padding: 1em 1em;
  margin: 0.75em 0.75em 0em 0em;
  border-radius: 0.1em;
}

form label {
  text-align: left;
  font-family: Aeradar-Bold, sans-serif;
}

form legend {
  font-size: 18px;
  font-family: Aeradar-Bold, sans-serif;
  font-weight: 600;
  width: 100%;
  color: rgb(15, 35, 103);
  margin-bottom: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid #d7d2cb;
  margin-top: 12px;
}

.comboCustom :deep(.v-input__control) {
  background-color: white !important;
  margin: 0.85em 0.75em 0em 0em !important;
  width: 100% !important;
}

.comboCustom :deep(.v-field__input) {
  width: 100vw;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.comboCustom :deep(.v-field) {
  border-radius: 0.1em !important;
}

.margin-mobile-top {
  margin-top: 1rem;
}

.language {
  font-size: 18px;
  font-family: Aeradar-Bold, sans-serif;
  font-weight: 600;
  color: rgb(15, 35, 103);
}

input {
  font-family: "Lato", sans-serif, "Avenir", Helvetica, Arial, sans-serif;
}

.horizontal-list {
  display: flex;
  list-style: none;
  padding: 0;
}

.horizontal-list li {
  margin-right: 6px;
}

.selected {
  border-bottom: 2px solid;
}

.oovv-img {
  width: 218px !important;
}

@media (min-width: 768px) {
  .subset-row {
    display: flex;
    flex-direction: row;
    margin-top: 1rem;
  }
  .margin-mobile-top {
    margin-top: 0px;
  }
}
@media (max-width: 768px) {
  .subset-row {
    flex-direction: column-reverse;
  }
}
</style>
