<template>
  <div class="modal">
    <div class="error-view">
      <img
        :style="`width: ${type === 'success' ? 68 : 120}px; height: ${
          type === 'success' ? 68 : 129
        }px`"
        :src="
          require(`@/assets/front/${
            type === 'success'
              ? 'group.png'
              : 'illustration-error-comunitation-down-copy.png'
          }`)
        "
      />
      <div class="error-view-text-wrapper">
        <p v-if="!hidetitle" class="error-view-title">
          {{ type === "success" ? "Éxito" : "Se ha producido un error" }}
        </p>
        <p
          class="error-view-subtitle"
          v-bind:class="{ 'normal-text': hidetitle }"
        >
          {{ message }}
        </p>
        <button @click="click" v-if="!hidetitle" class="cta">
          {{ "Volver" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: ["title", "message", "type", "ctatext", "hidetitle", "click"],
});
</script>

<style scoped>
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%;
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}
img {
  margin-top: 20px;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.error-view {
  display: flex;
  flex-direction: row;
  background-color: #fefefe;
  border-radius: 10px;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  min-width: 392px;
  max-width: max-content;
  align-items: flex-start;
}
.error-view-text-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 369px;
  text-align: left;
  margin-left: 32px;
}
.error-view-title {
  font-family: Aeradar-Bold, sans-serif;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 0px;
  color: black;
}
.error-view-subtitle {
  font-family: Lato;
  font-size: 14px;
  line-height: 22px;
  color: #565656;
}
.cta {
  align-self: unset;
  font-family: Lato;
  text-decoration: none;
  padding: 12px 64px;
  margin-top: 34px;
  width: min-content;
  border-radius: 14px;
  background-color: #2d69e1;
  font-family: Lato;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.cta:hover {
  background-color: #6297ff;
}

.normal-text {
  font-size: 18px;
  margin-top: 2rem;
}
@media (max-width: 640px) {
  .error-view {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-width: unset;
  }
  img {
    width: 181px;
  }
  .cta {
    align-self: center;
  }
  .error-view-text-wrapper {
    margin-left: 0px;
    margin-top: 46px;
    text-align: center;
  }
}
</style>
