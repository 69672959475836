import { Channel } from "@/models/channels";
import { CountriesResponse } from "@/models/country.interface";
import axios, { AxiosResponse } from "axios";

const sso_url = process.env.VUE_APP_SSO_URL;

export type preAltaForm = {
  title: string;
  firstSurname: string;
  name: string;
  secondSurname: string;
  birthDate: string | Date | null;
  citizenship: string;
  identificationDocument: {
    type: string;
    number: string;
  };
  email: string;
  telephone: {
    type: string;
    prefix: string;
    number: number | null;
  };
};

const mockForm = (channel: string): preAltaForm => {
  const identificationDocument = {
    type: "NI",
    number: "",
  };
  let prefix = "34";
  if (channel === "sudameris") {
    prefix = "595";
  }
  if (channel === "cclatil") {
    prefix = "972";
  }
  if (channel === "farmaclub") {
    prefix = "591";
    identificationDocument.type = "PP";
  }

  return {
    title: "MR",
    firstSurname: "aaa",
    name: "ee",
    secondSurname: "eaaa",
    birthDate: "05-03-1991",
    citizenship: "ES",
    identificationDocument: identificationDocument,
    email: "algarrobo@es.es",
    telephone: {
      type: "",
      prefix: prefix,
      number: 421423123,
    },
  };
};
const formEmpty = (channel: string): preAltaForm => {
  const identificationDocument = {
    type: "NI",
    number: "",
  };
  let prefix = "34";
  if (channel === "sudameris") {
    prefix = "595";
  }
  if (channel === "cclatil") {
    prefix = "972";
  }
  if (channel === "farmaclub") {
    prefix = "591";
    identificationDocument.type = "PP";
  }

  return {
    title: "MR",
    firstSurname: "",
    name: "",
    secondSurname: "",
    birthDate: null,
    citizenship: "ES",
    identificationDocument: identificationDocument,
    email: "",
    telephone: {
      type: "",
      prefix: prefix,
      number: null,
    },
  };
};

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

function formatDate(date: Date): string {
  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join("/");
}

const validateChannel = (channel: Channel): boolean => {
  return Object.values(Channel).includes(channel);
};

const preAlta = (params: preAltaForm, channel: string, locale: string) => {
  if (!validateChannel(channel as Channel)) throw Error("channel not valid");
  const basic = process.env.VUE_APP_SSO_BASIC;
  if (params.birthDate != null) {
    params.birthDate = formatDate(new Date(params.birthDate));
  }

  return axios.post(
    sso_url + `signup/${encodeURI(channel)}?locale=${locale}`,
    params,
    {
      headers: {
        Authorization: basic,
        "Content-Type": "application/json",
      },
    }
  );
};

const validateIp = (channel: string) => {
  const basic = process.env.VUE_APP_SSO_BASIC;

  return axios.get(sso_url + `signup/validate?channel=${channel}`, {
    headers: {
      Authorization: basic,
      "Content-Type": "application/json",
    },
  });
};

const countries = (lang: string): Promise<AxiosResponse<CountriesResponse>> => {
  return axios.get(process.env.VUE_APP_JANO_BASE_URL + `countries`, {
    headers: {
      Authorization: "Basic " + process.env.VUE_APP_JANO_LOGIN_BASIC,
      "Content-Type": "application/json",
      locale: lang,
    },
    data: {},
  });
};

export { preAlta, formEmpty, mockForm, validateIp, countries };
