import { validateIp } from "@/api/preAlta";
import { createRouter, createWebHistory } from "vue-router";
import LoginPage from "../pages/Login.vue";
import PreRegister from "../pages/PreRegister.vue";
import OpenIdError from "../pages/OpenIdError.vue";

const channels = process.env.VUE_APP_CHANNELS || [];
const multiLangChannels = process.env.VUE_APP_MULTILANG_CHANNELS || [];

// Función para redirección multi-idioma
const langRedirect = async (to: any, from: any, next: any) => {
  const envKey = `VUE_APP_${to.params.channel.toUpperCase()}_LANGUAGES`;
  const langAvailable = process.env[envKey] || [];
  const defaultLanguage = "es";
  const isLangIncluded =
    to.params.lang && langAvailable.includes(to.params.lang);

  if (!multiLangChannels.includes(to.params.channel)) {
    return to.params.lang
      ? next({ path: `/signup/${to.params.channel}` })
      : next();
  }

  if (isLangIncluded) {
    return next();
  }

  const lang = isLangIncluded ? to.params.lang : navigator.language;
  const targetLang = langAvailable.includes(lang) ? lang : defaultLanguage;

  next({ path: `/signup/${to.params.channel}/${targetLang}` });
};

const routes = [
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    path: "/signup/:channel/:lang?",
    name: "Pre Alta",
    component: PreRegister,
    beforeEnter: langRedirect,
  },
  {
    path: "/openid/error",
    name: "OpenId Error",
    component: OpenIdError,
  },
];

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

router.beforeEach(async (to, from, next) => {
  if (["Login", "Pre Alta", "OpenId Error"].includes(String(to.name))) {
    if (to.name === "Pre Alta") {
      if (!channels.includes(String(to.params.channel))) {
        return (window.location.href = "https://www.aireuropa.com/es/es/home");
      }
      try {
        await validateIp(String(to.params.channel));
      } catch {
        return (window.location.href = "https://www.aireuropa.com/es/es/home");
      }
    }
    return next();
  }
  return (window.location.href = "https://www.aireuropa.com/es/es/home");
});

export default router;
