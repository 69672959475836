import { createApp } from "vue";
import App from "./App.vue";
import Router from "./router";
import i18n from "./i18n";
import vuetify from "./plugins/vuetify";

const vue = createApp(App);

vue.use(Router);
vue.use(i18n);
vue.use(vuetify);

vue.mount("#app");
