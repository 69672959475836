<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      flex: auto;
      justify-content: center;
      align-items: center;
    "
  >
    <div class="component-login submenu-shadow">
      <div class="logo">
        <img
          alt="Air Europa-SkyTeam"
          src="@/assets/front/icons/logo_AirEuropa_SkyTeam.png"
        />
      </div>
      <template v-if="!sessionIt">
        <div class="template-login">
          <h3 id="message-login">{{ $t("message.login") }}</h3>
          <div class="container-info" v-if="textError">
            {{ textError }}
            <span class="container-info-close" @click="close">X</span>
          </div>
          <co-ui-input
            type="text"
            name="user"
            class="user"
            :placeholder="$t('message.user')"
            v-model="user"
            @keyup.enter="onClick"
          />
          <co-ui-input
            type="password"
            name="pass"
            class="pass"
            :placeholder="$t('message.pass')"
            v-model="pass"
            @keyup.enter="onClick"
          />
          <p>
            <a href="https://www.aireuropa.com/" class="block">{{
              $t("message.forgotten")
            }}</a>
          </p>
          <div class="button-login">
            <co-ui-form-button
              text="Login"
              :show="show"
              :isCompleteForm="isCompleteForm"
              :onClick="onClick"
            />
          </div>
          <p style="padding-bottom: 0.5rem">
            {{ $t("message.suma_member") }}
          </p>
          <a :href="$t('message.register_url')">{{ $t("message.register") }}</a>
        </div>
      </template>
      <template v-if="this.params.output == 'download' && sessionIt">
        <div>
          <h3 class="message-result">{{ $t("message.success_download") }}</h3>
          <p class="message-result">{{ $t("message.lang_download") }}</p>
          <a id="pkpass" :href="pkpassUrl" download="AirEuropaPassbook.pkpass"
            >AirEuropa Passbook Suma.</a
          >
        </div>
      </template>
      <template v-if="this.params.output == 'email' && sessionIt">
        <div class="message-result">
          <h3>{{ $t("message.success_sendEmail") }}</h3>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CoUiInput from "@/components/CoUiInput";
import CoUiFormButton from "@/components/CoUiFormButton";
import { doLogin } from "../api";

export default {
  name: "CoLogin",
  components: {
    CoUiInput,
    CoUiFormButton,
  },
  data: function () {
    return {
      user: "",
      pass: "",
      params: "",
      textError: "",
      sessionIt: null,
      pkpassUrl: "",
      show: false,
    };
  },
  mounted() {
    let lang = this.$route.query.lang
      ? this.$route.query.lang.toLowerCase()
      : navigator.language.substr(0, 2);
    switch (lang) {
      case "es":
        break;
      case "en":
        break;
      case "es_ES":
        lang = "es";
        break;
      case "en_EN":
        lang = "en";
        break;
    }
    this.$i18n.locale = lang;
    this.$route.query.lang = lang;
    this.params = this.$route.query;
  },
  computed: {
    isCompleteForm() {
      return this.user && this.pass ? true : false;
    },
  },
  methods: {
    validate() {
      return this.user && this.pass ? true : false;
    },
    onClick() {
      this.show = true;

      this.textError = "";

      if (this.validate()) {
        doLogin(this.params, this.user, this.pass, (sessionIt, data, error) => {
          if (error) {
            if (error === "bad_hash") {
              document.location.href = "/openid/error";
              return;
            } else if (this.params.continueTo === "openid") {
              this.show = false;
              this.textError = error;
              return;
            }
            this.show = false;
            this.textError = this.$i18n.t(`message.${error}`);
            return;
          }
          switch (this.params.output) {
            case "download":
              this.baseDownloadFile(data, "application/vnd.apple.pkpass");
              this.sessionIt = sessionIt;
              break;
            case "email":
              this.sessionIt = sessionIt;
              break;
            default:
              document.location.href = data.data;
          }
        });
      }
    },
    baseDownloadFile(data, contentType) {
      const blob = new Blob([data.data], { type: contentType });
      let reader = new FileReader();

      // eslint-disable-next-line no-unused-vars
      reader.onload = () => {
        if (reader.readyState == 2) {
          const downloadLink = document.createElement("a");
          downloadLink.href = reader.result;
          downloadLink.download = "AirEuropaPassbook.pkpass";
          downloadLink.click();
          this.pkpassUrl = reader.result;
        }
      };

      if (blob) {
        reader.readAsDataURL(blob);
      }

      reader.onerror = function () {
        reader.abort();
      };
      return true;
    },

    close() {
      this.textError = "";
    },
  },
};
</script>

<style lang="less">
@import "../assets/less/_variables.less";

@media screen and (max-width: 460px) {
  html,
  body,
  #app {
    background-color: white !important ;
  }

  .copyright {
    color: rgb(0, 114, 206) !important;
    font-weight: 600;
  }

  .component-login {
    box-shadow: none !important;
    border: none !important;
  }
}

.message-result {
  text-align: center;
  font-weight: 600;
}

img {
  width: 218px;
}

.component-login {
  background-color: white;
  border: 1px solid rgb(218, 220, 224);
  border-radius: 8px;
  box-shadow: 0px 0px 10px 8px #0061af;
  color: rgb(75, 75, 72);
  padding: 3.4em 2.8em;
  max-width: 26rem !important;
}

.component-login a {
  color: #2d69e1;
  text-decoration: none;
  font-weight: 700;
}

.component-login #message-login {
  text-align: center;
  font-size: 24px;
  font-family: Aeradar-Bold, sans-serif;
  font-weight: 600;
}

.component-login p {
  padding: 1.5em 0;
  margin: 0;
}

.component-login .logo {
  text-align: center;
}

.container-info {
  position: relative;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  padding: 10px;
}

.container-info-close {
  cursor: pointer;
  padding: 0.75rem 1.25rem;
  top: 0;
  right: 0;
  position: absolute;
  font-weight: 700;
}
#pkpass {
  display: table;
  margin: 0 auto;
  text-decoration: underline;
  font-weight: 600;
}

.has-error {
  color: red;
  border-radius: 1px solid red;
}

.invalid-feedback {
  color: #c13030;
  font-size: 15px;
  font-style: oblique;
}

.user {
  background-image: url("../assets/front/icons/ico-user-G.png");
  background-repeat: no-repeat;
  background-position: 5% 65%;
}

.pass {
  background-image: url("../assets/front/icons/ico-locked-G.png");
  background-repeat: no-repeat;
  background-position: 5% 65%;
}

*,
::before,
::after {
  box-sizing: revert !important;
}
html,
::before,
::after {
  overflow-y: initial !important;
  overflow-x: initial !important;
}
</style>
