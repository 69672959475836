<template>
  <div
    class="main"
    style="
      display: flex;
      flex-direction: column;
      flex: auto;
      justify-content: center;
      align-items: center;
    "
  >
    <img class="plane" src="@/assets/front/airplane.png" />
    <img class="plane2" src="@/assets/front/airplane.png" />
    <div id="main-form" class="form">
      <Form />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Form from "@/components/signup/FormComponent.vue";
import { Channel } from "@/models/channels";
export default defineComponent({
  components: {
    Form,
  },
  mounted() {
    let channel: Channel = this.$route.params.channel as Channel;
    if (channel === Channel.PLANETTO || channel === Channel.CCLATIL) {
      this.$i18n.locale = "en";
    } else if (channel === Channel.OOVV) {
      this.$i18n.locale = this.$route.params.lang as string;
    } else {
      this.$i18n.locale = "es";
    }
  },
});
</script>

<style scoped>
.plane {
  display: none;
  position: absolute;
  z-index: 1;
  left: 16px;
  top: 30px;
}
@keyframes run-right {
  0% {
    left: -150px;
  }
  25% {
    left: 70%;
    transform: translateX(-150px);
    transform: translateY(100px);
  }
  100% {
    left: 70%;
    transform: translateY(60px);
  }
}
@-webkit-keyframes run-right {
  0% {
    left: -150px;
  }
  25% {
    left: 70%;
    transform: translateX(-150px);
    transform: translateY(100px);
  }
  100% {
    left: 70%;
    transform: translateY(60px);
  }
}
.plane2 {
  display: none;
  position: absolute;
  z-index: 1;
  left: 16px;
  top: 50%;
}
@keyframes run-top {
  0% {
    left: -150px;
    transform: translateX(-750px);
  }
  40% {
    left: 70%;
    transform: translateX(-150px);
    transform: translateY(-20px);
  }
  100% {
    left: 70%;
  }
}
@-webkit-keyframes run-top {
  0% {
    left: -150px;
    transform: translateX(-750px);
  }
  40% {
    left: 70%;
    transform: translateX(-150px);
    transform: translateY(-20px);
  }
  100% {
    left: 70%;
  }
}
.main {
  background-image: url("../assets/front/enroll-web-b.webp");
}
.form {
  z-index: 2;
  background-color: rgb(246, 246, 246);
  border: 1px solid rgb(246, 246, 246);
  box-shadow: 0px 0px 10px 8px #0061af;
  color: rgb(75, 75, 72);
  max-width: 100%;
  padding: 24px 24px 24px 8%;
  margin-left: auto;
  flex: auto;
}

@media (min-width: 768px) {
  .form {
    width: 58%;
    max-width: 58%;
    padding: 42px 36px 35px 8%;
  }
  .plane {
    display: unset;
    animation: linear infinite;
    animation-name: run-right;
    animation-duration: 50s;
    -webkit-animation: linear infinite;
    -webkit-animation-name: run-right;
    -webkit-animation-duration: 50s;
  }
  .plane2 {
    display: unset;
    animation: linear infinite;
    animation-name: run-top;
    animation-duration: 90s;
    -webkit-animation: linear infinite;
    -webkit-animation-name: run-top;
    -webkit-animation-duration: 90s;
  }
}

@media (min-width: 1024px) {
  .form {
    width: 47%;
    max-width: 47%;
    padding: 42px 36px 35px 8%;
  }
}
</style>
