<template>
  <footer id="footer">
    © {{ actualYear }} Air Europa Líneas Aéreas S.A.U. <br />
    Ctra. Arenal - Llucmajor, km.21,5 - 07620 Llucmajor · Baleares · España
  </footer>
</template>

<script>
export default {
  computed: {
    actualYear() {
      return new Date().getFullYear(); // returns the current year
    },
  },
};
</script>

<style scoped>
#footer {
  box-sizing: border-box;
  -webkit-box-sizing: border-box !important;
  bottom: 0px;
  width: 100%;
  line-height: 20px;
  background-color: #2d69e1;
  text-align: center;
  color: white;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  margin: 0 auto;
  max-height: 91px;
}
</style>
