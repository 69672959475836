<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 100vh;
    "
  >
    <router-view />
    <FooterComponent />
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import { Options, Vue } from 'vue-class-component';
import FooterComponent from './components/common/FooterComponent.vue';

@Options({
  components: {
    FooterComponent,
  }
})
export default class App extends Vue {
}
</script>

<style>

html {
  background-color: #2d69e1;
}
body {
  margin: 0;
}
#app {
  font-family: 'Lato', sans-serif,'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #2d69e1;
}

@font-face {
  font-family: "Aeradar-Bold";
  font-style: normal;
  font-weight: 700;
  src: url("https://campaign.aireuropa.com/email/others/fonts/aeradar-bold-webfont.woff2")
      format("woff2"),
    url("https://campaign.aireuropa.com/email/others/fonts/aeradar-bold-webfont.woff")
      format("woff");
}
</style>