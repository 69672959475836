import { LoginQueryParams } from "@/models/loginQueryParams";
import axios from "axios";
import qs from "qs";
import uuid4 from "uuid4";

const sso_url = process.env.VUE_APP_SSO_URL;

const getRiskifiedID = () => {
  let riskifiedID = localStorage.getItem("riskifiedSID");
  if (!riskifiedID) {
    riskifiedID = uuid4();
    localStorage.setItem("riskifiedSID", riskifiedID);
  }
  return riskifiedID;
};

const loadRiskified = async () => {
  const riskifiedID = getRiskifiedID();
  const storeDomain = process.env.VUE_APP_JANO_STORE_DOMAIN;
  const protocol = document.location.protocol;
  const src = `${protocol}//beacon.riskified.com?shop=${storeDomain}&sid=${riskifiedID}&v=${Date.now()}`;

  try {
    await axios.get(src);
  } catch (error) {
    console.error(error);
  }
  return { riskifiedID: riskifiedID };
};

const doLogin = async (
  params: LoginQueryParams,
  user: string,
  pass: string,
  callback: (sessionIt: string, data: any, error: string | null) => void
) => {
  if (params.continueTo === "openid") {
    loginOpenID(params, user, pass)
      .then((response) => {
        return callback(params.output, response.data, null);
      })
      .catch((eerr) => {
        console.log(eerr.response.status);
        if (eerr.response && eerr.response.status === 404) {
          return callback(params.output, null, "bad_hash");
        }
        return callback(
          params.output,
          null,
          eerr.response.data.errorDescription
        );
      });
    return;
  }

  const riskifiedResponse = await loadRiskified();
  loginJano(user, pass, riskifiedResponse.riskifiedID)
    .then((response) => {
      serviceCall(params, response.data.accessToken, response.data.idUser)
        .then((response) => {
          const data = {
            data: response.data.url || response.data.data,
          };
          return callback(params.output, data, null);
        })
        .catch(() => {
          return callback(params.output, null, "error_sso");
        });
    })
    .catch(() => {
      return callback(params.output, null, "error_login");
    });

  return;
};

const loginOpenID = (params: LoginQueryParams, user: string, pass: string) => {
  const basic = process.env.VUE_APP_SSO_BASIC;

  return axios.post(
    sso_url + `openid/login`,
    qs.stringify({
      hash: params.hash,
      username: user,
      password: pass,
      lang: params.lang,
    }),
    {
      headers: {
        Authorization: basic,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
};

const loginJano = (user: string, pass: string, riskifiedID: string) => {
  const config = {
    headers: {
      Authorization: "Basic " + process.env.VUE_APP_JANO_LOGIN_BASIC,
      "Content-Type": "application/json",
      rskid: riskifiedID,
    },
  };

  const data = JSON.stringify({
    password: pass,
    username: user,
  });

  return axios.post(process.env.VUE_APP_JANO_LOGIN_URL, data, config);
};

const serviceCall = (
  params: LoginQueryParams,
  accessToken: string,
  idUser: string
) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + accessToken,
      locale: params.lang == "es" || params.lang == "es-ES" ? "es-ES" : "en-US",
    },
  };

  let url = process.env[
    "VUE_APP_SSO_" + params.continueTo?.toUpperCase() + "_URL"
  ].replace("**JanoIdUser**", idUser);
  url = url.replace("**lang**", params.lang);
  url = url.replace("**action**", params.feature || "landing_page");
  if (params["url-ok"]) {
    url = url + "&promoCode=" + params["url-ok"];
  }

  if (params.continueTo === "europcar") {
    return axios.post(url, {}, config);
  }
  if (params.continueTo === "pkpass") {
    if (params.output === "download") {
      url += "pkpass";
      return axios.get(url, config);
    } else {
      url += "sentPkpass";
      return axios.post(url, {}, config);
    }
  }
  return axios.get(url, config);
};

export { doLogin };
